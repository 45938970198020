import { Logo } from './../_partials'

export default function Header() {
  return (
    <header className="bg-main-color border-b">
      <div className="td-container flex justify-between items-center py-4">
        <Logo />

        <a href="https://rtm-documentation.vercel.app/" rel="noreferrer" target="_blank" className="underline font-medium">Documentation</a>
      </div>
    </header>
  )
}
