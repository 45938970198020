// RTM jQuery-ui-sortable as externally to sorting columns and tasks

import { db } from './../database'
import $ from 'jquery';

const sortable = import('jquery-ui-bundle');

// Column sorting
export const sortColumn = () => {
  sortable.then(() => {
    $('.sort-column').sortable({
      axis: "x",
      handle: ".column-sort-handler",
      placeholder: "sortable-placeholder",
      update: (event) => {
        const document = event.currentTarget.all
        let columnsTable = []
        let wsId = 0;
        let order = 1;
        for (let index = 0; index < document.length; index++) {
          if ($(document[index]).hasClass('sort-column')) {
            wsId = $(document[index]).data('workspace-id')
            columnsTable = db.getTableDataByRelationId('columns', 'workspaces', wsId)
          }

          if (wsId && $(document[index]).hasClass('sort-column-content')) {
            const clId = $(document[index]).data('column-id')
            columnsTable.forEach((clt) => {
              if (clt.id === parseInt(clId)) {
                const upColumn = {
                  ...clt,
                  order: order
                }
                db.updateTable('columns', upColumn, clt.id)
                order++;
              }
            })
          }
        }
      }
    })
  })
}

// Task sorting
export const sortTask = () => {
  sortable.then(() => {
    $('.sort-task').sortable({
      handle: ".task-sort-handler",
      placeholder: "sortable-placeholder",
      update: (event) => {
        const document = event.currentTarget.all
        let tasksTable = [];
        let wsId = 0;
        let clId = 0;
        let order = 1;

        for (let index = 0; index < document.length; index++) {
          if ($(document[index]).hasClass('sort-column')) {
            wsId = $(document[index]).data('workspace-id')
            tasksTable = db.getTableDataByRelationId('tasks', 'workspaces', wsId)
          }

          if (wsId && $(document[index]).hasClass('sort-column-content')) {
            clId = $(document[index]).data('column-id')
            order = 1;
          }

          if (wsId && clId && $(document[index]).hasClass('sort-task-content')) {
            const tsId = $(document[index]).data('task-id')
            tasksTable.forEach((tst) => {
              if (tst.id === parseInt(tsId)) {
                const upTask = {
                  ...tst,
                  columns_id: clId,
                  order: order
                }
                db.updateTable('tasks', upTask, tst.id)
                order++;
              }
            })
          }
        }
      }
    })
  })
}