import { Link } from "react-router-dom"

export default function EnterButton({ onClick, path }) {
  if (path) {
    return <Link title="Go to" className="p-1 bg-gray-200 border hover:bg-gray-100" to={path}>
      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
      </svg>
    </Link> 
  } else {
    return (
      <button className="p-1 bg-gray-200 border hover:bg-gray-100" onClick={onClick}>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
        </svg>
      </button>
    )
  }
}
