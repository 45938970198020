import { Outlet } from 'react-router-dom'
import { Header } from './_layouts'

export default function Main() {
  return (
    <div className="overflow-hidden pb-8">
      <Header />
      <Outlet />
    </div>
  )
}
