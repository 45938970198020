// Description Editor
export const specialString = (string) => {
    let formatStr = string;

    formatStr = formatStr.replace(/</g, '&lt;')
    formatStr = formatStr.replace(/>/g, '&gt;')

    // Bold
    formatStr.match(/\*\*\S(.)[^**]+\S\*\*/g)?.forEach((str) => {
        const trimStr = str.replace(/\*\*/g, '')
        formatStr = formatStr.replace(str, '<b>' + trimStr + '</b>')
    })

    // Italic
    formatStr.match(/--\S(.)[^--]+\S--/g)?.forEach((str) => {
        const trimStr = str.replace(/--/g, '')
        formatStr = formatStr.replace(str, '<i>' + trimStr + '</i>')
    })

    // Underline
    formatStr.match(/__\S(.)[^__]+\S__/g)?.forEach((str) => {
        const trimStr = str.replace(/__/g, '')
        formatStr = formatStr.replace(str, '<u>' + trimStr + '</u>')
    })

    // Code format
    formatStr.match(/```(.)[^```]+```/gs)?.forEach((str) => {
        const trimStr = str.replace(/```/g, '')
        formatStr = formatStr.replace(str, '<pre><code>' + trimStr + '</code></pre>')
    })

    // Heading
    formatStr.match(/##\S(.)[^##]+\S##/g)?.forEach((str) => {
        const trimStr = str.replace(/##/g, '')
        formatStr = formatStr.replace(str, '<h3>' + trimStr + '</h3>')
    })

    // Link
    formatStr.match(/{\S(.)+\S]/g)?.forEach((str) => {
        let trimStr = str.replace(/{/g, '');
        trimStr = trimStr.replace(/]/g, '');
        trimStr = trimStr.split(/(}\[)/g);

        if (trimStr.length === 3 && trimStr[1] === '}[') {
            formatStr = formatStr.replace(str, '<a href=' + trimStr[2] + ' target="_blank">' + trimStr[0] + '</a>')
        }
    })

    return formatStr;
}

// Get time
export const getCreatedTime = (time) => {
    return new Date(time).toDateString()
}

// Set current time
export const setCreatedTime = () => {
    return new Date().getTime()
}
